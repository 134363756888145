import { Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import { Some } from "../../../../utils/Some";
import Switches from "../../../UI-Components/Switches";
import questionMark from "../../../../assets/Images/Icons/question-mark.svg";

interface Props {
  readonly handleChange: (data: { name: string; val: string | boolean }) => void;
  readonly handleClickEvent: (boolean: boolean) => void;
  readonly eventList: EventList[];
  readonly dependentEventId: number;
  readonly hasDependentEvent: boolean;
  readonly selectedEvent?: EventList;
  readonly message: string;
}

export function EventDependentActivity({
  selectedEvent: selectedDependentEvent,
  dependentEventId,
  eventList,
  handleChange,
  handleClickEvent,
  hasDependentEvent,
  message,
}: Props) {
  const [showEventsDropdown, setShowEventDropDown] = useState(hasDependentEvent);
  const [selectedEvent, setSelectetdEvent] = useState<any>(undefined);
  useEffect(() => {
    if (Some(dependentEventId) && dependentEventId && hasDependentEvent) {
      setShowEventDropDown(true);
      setSelectetdEvent(eventList.find((event) => event.eventId === dependentEventId));
    }
  }, [dependentEventId, eventList, hasDependentEvent]);

  const handleChangeSwitch = (data: { name: string; val: boolean }) => {
    setShowEventDropDown(data.val);
    handleChange(data);
    handleClickEvent(data.val);
  };

  const onSelectChange = (eventData: any) => {
    const data: { name: string; val: string } = {
      name: "dependentEventId",
      val: eventData.eventId,
    };
    handleChange(data);
    setSelectetdEvent(eventData);
    handleClickEvent(true);
  };

  return (
    <Row className="grid grid_hr">
      <Row>
        <Col md={15} lg={14} xl={9} xxl={7}>
          <h6 className="step4_subtitle margin_4_top">
            Does this activity depends on another activity
            <Tooltip placement="top" title="Enable Dependent Activity">
              <img alt="" src={questionMark} className="padding_8_left" />
            </Tooltip>
          </h6>
        </Col>
        <Col span={1}>
          <Row className="step2_switch2 margin_0_top">
            <Switches
              id="hasDependentEvent"
              name="hasDependentEvent"
              handleChange={handleChangeSwitch}
              checked={showEventsDropdown}
            />
          </Row>
        </Col>
      </Row>
      {showEventsDropdown && (
        <Row>
          <Grid item xs={5}>
            <h6 className="margin_0_top margin_10_bottom" data-testid="activitiesText-EventDependentActivity">
              Activities
            </h6>
            <div data-testid="selectActivityDropdown-EventDependentActivity">
              <Select
                isClearable
                isSearchable
                name="dependentEventId"
                value={selectedEvent}
                options={eventList.filter((option) => option.eventId !== selectedDependentEvent?.eventId)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => String(option.eventId)}
                onChange={onSelectChange}
              />
            </div>
            <h6 className="margin_10 time-zone-text">
              Checkin to the activity from dropdown is prerequiste before checkin to the current activity
            </h6>
            <span
              className="error-msg highlight"
              id="event-name-error"
              data-testid="errorMessage-EventDependentActivity"
            >
              {message}
            </span>
          </Grid>
        </Row>
      )}
    </Row>
  );
}
